.elementAddNewAsset{
    /*OLD CSS*/
    margin-left: 1vw;
    /*font-size: large;*/
    font-weight: bolder;
    /*padding:0.3vh 0.3vw;*/
    background-color: lightblue;
    border: solid 1px blue;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.5vw;
    /*NEW CSS*/
    width: 1.5vw;
    height: 1.5vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.table-header-cell {
    color: rgb(73, 86, 114) !important;
    font-size: 1.97vw !important;
    font-weight: bold !important;


    padding-top: 1.5vw !important;
    padding-bottom: 1.5vw !important;
    padding-right: 2vw !important;
    padding-left: 2vw !important;
}

.table-header-cell-reparto-linea {
    color: rgb(73, 86, 114) !important;
    font-size: 1.2vw !important;
    font-weight: bold !important;


    padding-top: 1vw !important;
    padding-bottom: 1vw !important;
    padding-right: 1vw !important;
    padding-left: 1vw !important;
}
.table-primary-level-cell{
    font-size: 1.2vw !important;
    padding-top: 1vw !important;
    padding-bottom: 1vw !important;
    padding-right: 2vw !important;
    padding-left: 2vw !important;
}
.table-primary-level-cell-reparto-linea{
    font-size: 1vw !important;
    padding-top: 1vw !important;
    padding-bottom: 1vw !important;
    padding-right: 1vw !important;
    padding-left:  1vw !important;
}
.table-arrow-icon{
    width: 2vw !important;
    height: 2vw !important;
}
.table-box{
    padding:0 !important;
}
.table-box-intern{
    margin:.5vw 1vw .5vw 1vw !important;
    padding:0 !important;
}
.table-box-intern-title{
    margin:0 !important;
    font-size: 1.2vw !important;
    font-weight: bold !important;
    display: flex !important;
    align-items: center !important;
    margin: .8vw 0 .8vw 0 !important;

}
.table-box-intern-title-reparti-linee{
    margin:0 !important;
    font-size: 1.2vw !important;
    font-weight: bold !important;
    display: flex !important;
    align-items: center !important;
    margin: .1vw 0 .1vw 0 !important;

}
.table-box-intern-title-stabilitimenti{
    margin:0 !important;
    font-size: 1.2vw !important;
    font-weight: bold !important;
    display: flex !important;
    align-items: center !important;
    margin: 0.5vw 1vw 0.1vw 1vw !important;

}
.table-header-cell-second-level{
    color: rgb(73, 86, 114) !important;
    font-size: 1.2vw !important;
    font-weight: bold !important;

    padding-top: .5vw !important;
    padding-bottom: .5vw !important;
    padding-right: 2vw !important;
    padding-left: 2vw !important;
}
.table-secondary-level-cell{

    font-size: 1vw !important;


    padding-top: .5vw !important;
    padding-bottom: .5vw !important;
    padding-right: 2vw !important;
    padding-left: 2vw !important;
}

.menu-button.menu-container {
    position: fixed;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000;
}

.menu-button.menu-container.ball {
    width: 30px;
    height: 30px;
    background-color: #333;
    border-radius: 50%;
    margin-bottom: 10px;
    transition: background-color 0.3s ease-in-out;
}

.elementTitleAggiungiStabilimento{
    font-size: 1.3vw;
    font-weight: bold;
}

.elementTitleAggiungiReparto{
    font-size: 1.1vw;
    font-weight: bold;
}

.elementTitleAggiungiLinea{
    font-size: 1vw;
    font-weight: bold;
}

.boxAddLinea{
    display: flex;
    align-items: center;
    
}