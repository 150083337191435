.bodyFooter {
    
   
    /*OLD CSS*/
    /*width: 100%;*/
    height: 5vh;
    background-color: black;
    margin-top: auto;
    /*NEW CSS*/
    max-width: 86.67vw;

}