.boxElementTitoloSchedeLink:link, .boxElementTitoloSchedeLink:active, .boxElementTitoloSchedeLink:visited  {
    /*OLD CSS*/
    /*font-size: small;*/
    text-decoration: none;
    /*margin-right: 10px;*/
    color: black;

    /*NEW CSS*/
    margin-right: .84vw;

}
.boxElementTitoloSchede {
    /*OLD CSS*/
    /*font-size: small;*/
    text-decoration: none;
    /*margin-right: 10px;*/
    /*NEW CSS*/

}

.bodyElementTitoloSchede {
    /*OLD CSS*/
    /*padding: 5px 5px;*/
    display: flex;
    background-color: white;
    /*margin-right: 10px;*/
    cursor: pointer;
    align-items: center;

    /*NEW CSS*/
    /*padding:1vh 1.12vw 1vh 1.12vw;*/
    margin-right: 0.5vw;
    padding: 0.5vw 1.12vw 0.5vw 1.12vw;
}

.activeSchedaTitoloElementTitoloSchede{
    background-color: lightgray;
}

.boxTitoloSchedeExplore {
    /*OLD CSS*/
    display: flex;
    /*NEW CSS*/
    font-size: .97vw;
    background-color: #f4f8fb;
}