.bodyMaterialExtendTableAlert {
    background-color: white;
    padding: 10px 20px;
    border-radius: 5px;
}

.bodyTableMaterialExtendTableAlert {
    /*cosi gli piace alla libreria per mostrarne 5 nella lista compatta*/
    height: 274px;

}

.titleMaterialExtendTableAlert{
    display: flex;
    justify-content: space-between;
    align-items: center;

}