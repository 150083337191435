.bodyMainReportMainteneanceStory {
    /*OLD CSS*/
    background-color: white;
    color: black;
    /*padding: 10px;*/
    border-radius: 5px;
    border: solid 1px #F4F4F4;
    width: 70vw;

    /*NEW CSS*/
    padding: 2vw 2vw 2vw 2vw;
    max-height: 77vh;
}



.boxTitleReportMainteneanceStory {
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*margin: auto*/

    /*NEW CSS*/
    font-size: 1.7vw;
    margin-bottom: 1vw;
}

.elementClosignSchedaReportMainteneanceStory {
    cursor: pointer;
    margin: 0
}

.elementTitleReportMainteneanceStory {
    margin: 0
}

.boxActivityList {
    display: flex;
    justify-content: space-between;
}

.elementActivity {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.elementInputFormReport {
    background-color: #F4F4F4;
    border-radius: 5px;
    border: solid 1px lightgray;
}

.elementFlexDimensionOne {
    flex: 1;
}

.elementFlexDimensionThree {
    flex: 20;
}

.elementListActivity {
    list-style-type: none;
    padding-inline-start: 10px;
}

.elementInputMaxDimension {
    max-width: 100px;
    max-height: 1.2vw;
}

.elementAlignTextLeft {
    margin-left: 0;
}


.elementReportInterventionBody {
    display: flex;
}

.elementReportColumnLeft {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.boxRowReportCulimnRight{
    flex: 10;
    display: flex;
    justify-content: space-between;
    margin:0vw;
}

.elementReportColumnRight {
    /*OLD CSS*/
    border: solid 1px black;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /*NEW CSS*/
    font-size: 0.97vw;
}

.elementReportDateLeftColumn {
    /*OLD CSS*/
    font-size: 1vw;

    /*NEW CSS*/
    margin-bottom: 0.3vw;
    font-weight: 700;
}


.elementReportHourLeftColumn {
    font-size: 0.7vw;
}
.elementButtonModifyDelete {
    font-size: 0.7vw;
    margin-top: 0.5vw;
}
.elementAttivitaMainteneanceStoriCompleted {
    color: green
}

.elementAttivitaMainteneanceStoriIncompleted {
    color: red;
}

.boxSubReportCulimnRight {
    /*OLD CSS*/
    width: 50%;
    /*margin: 0 10px;*/
    /*NEW CSS*/
    margin: 0.6vw 1vw 0.6vw 1vw;
    font-size: 1.2vw;
}
.correctionSecondBoxSubReportCulimnRight{
    width: 100%;
}
.bodydeleteModalIntervention {
    position: fixed;
    background-color: white;
    left:22vw;
    top:10vw;
    border-radius: 5px;
    width: 50vw;

}
.boxAllDeleteModalIntervention {
    padding: 2vw 4.58vw 2vw 4.58vw;
}

.boxTitleDeleteModalIntervention {
    display: flex;
    justify-content: space-between;
    margin-bottom: .7vw;
    align-items: center;
}
.deleteModalInterventionObjectButton{
    display: flex;
}
.elementButtonSubmitDeleteModalInterventionObjectButton{
    width: 100%;
    height: 2.2vw;
    font-size: 1.5vw;
    margin-top: 1vw;
    font-weight: 700;
}