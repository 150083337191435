.bodySchedeMachinery{
    display: flex;
    /*margin-top: 2.11vh;*/
    /*margin-bottom: 2vh;*/

    /* NEW CSS */
    margin-top: 1vw;
    margin-bottom: 1vw;
}

.elementSchedaMachinery{
   
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-size: 1.2vw;
    font-weight: bolder;
    border-radius: 5px 0 5px 0;
    /*padding:1vh 1vw;*/

    /* NEW CSS */
    padding: 0.5vw 1vw;
}

.elementActiveSchedaMachinery{
    background-color: lightblue;
}