@-moz-document url-prefix() {
    #BoxAltoLogin {
        background-color: #1c7293;
        display: flex;
        width: 100%;
    }
    #DescrizionePiattaforma {
        color: white;
        margin: auto;
    }
    #ImmagineLogo {
        margin-left: 3vw;
        margin-top: 1vw;
        margin-bottom: 1vw;
        width: 10vw;
        height: 6vw;
    }
    #ImmagineTrasf {
        flex: 6;
    }
    #FormLogin {
        margin: auto;
        flex: 5;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #BoxImmaForm {
        width: 100%;
        display: flex;
    }
    #LogoTerna {
        width: 15vw;
        height: 7vw;
        background-color: gray;
        margin-bottom: 2vw;
    }
    #FormGrup {
        margin-bottom: 1vw;
        width: 25vw;
    }
    .FormGrupInput {
        /* OLD CSS */
        width: auto;
        /*font-size: 1rem;*/
        margin-top: 1vw;
        padding: 1vw;
        border-radius: 20px;
        border: 2px solid rgb(220, 220, 220);
        /* NEW CSS */
        font-size: 1.2vw;

    }
    .FormGrupInput::placeholder {
        /*NEW CSS*/
        font-size: 1.2vw;

    }
    #StcrittaLOGIN {
        font-size: 2vw;
        color: #1c7293;
        font-weight: bold;
    }
    .buttonStyle {
        /*OLD CSS*/
        background-color: #1c7293;
        margin-top: 3vw;
        margin-left: 20vw;
        padding: 0.5vw 1vw;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        /*NEW CSS*/
        font-size: 1.2vw;
    }
    .NomePulsante {
        font-size: 1vw;
        color: white;
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    #BoxAltoLogin {
        background-color: #1c7293;
        display: flex;
        width: 100%;
    }
    #DescrizionePiattaforma {
       
        font-size: 2vw;
        color: white;
        margin: auto;
    }
    #ImmagineLogo {
        margin-left: 3vw;
        margin-top: 1vw;
        margin-bottom: 1vw;
        width: 10vw;
        height: 6vw;
    }
    #ImmagineTrasf {
        width: 40%;
        flex: 3;
    }
    #FormLogin {
        margin: auto;
        flex: 5;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #BoxImmaForm {
        width: 100%;
        display: flex;
    }
    #LogoTerna {
        width: 15vw;
        height: 7vw;
        background-color: gray;
        margin-bottom: 2vw;
    }
    #FormGrup {
        margin-bottom: 1vw;
        width: 25vw;
    }
    .FormGrupInput {
        /* OLD CSS */
        width: auto;
        /*font-size: 1rem;*/
        margin-top: 1vw;
        padding: 1vw;
        border-radius: 20px;
        border: 2px solid rgb(220, 220, 220);
        /*NEW CSS*/
        font-size: 1.2vw;
    }
    .FormGrupInput::placeholder {
        /*NEW CSS*/
        font-size: 1.2vw;
    }
    #StcrittaLOGIN {
        font-size: 2vw;
        color: #1c7293;
        font-weight: bold;
    }
    .buttonStyle {
        /*OLD CSS*/
        background-color: #1c7293;
        margin-top: 3vw;
        margin-left: 20vw;
        padding: 0.5vw 1vw;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        /*NEW CSS*/
        font-size: 1.2vw;
    }
    .NomePulsante {
        font-size: 1vw;
        color: white;
    }
}


.elementLableLogin{
    text-align: left;
}