.bodyTripleDonut {
    background-color: white;
    /* NEW CSS */

    margin-right: 2vw;
    /* margin-bottom: 2vh; */
    /* NEW CSS */


    border-radius: 5px;
    /* padding:10px; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);

}

.singleDonut {
    display: flex;
    /* NEW CSS */
    justify-content: space-between;
    align-items: flex-end;

    margin-left: 1vw;
    margin-right: 1vw;
    padding: 0 2vw;

    margin-bottom: 2.5vw;
    margin-top: 3vw;

    /* NEW CSS */

}

/* padre */
.titleTripleDonut {
    display: flex;
    align-items: bottom;
    justify-content: space-between;
    flex: 1;
    margin-left: 1vw;
    margin-right: 1vw;


}

/*figlio*/
.elementTitleTripleDonut {
    font-size: 1.2vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 23vw;
}

/*l'altezza deve essere il doppio del font*/
/*figlio*/
.buttonTripleDonutLeft {
    font-weight:bold;
    background-color: white;
    color: lightgray;
    margin: 0;
    font-size: 0.8vw;
    border-radius: 5px 0 0 5px;
    border: solid lightgray 1px;
    height: 60%;
}

.buttonTripleDonutRight {
    color: lightgray;
    font-weight:bold;
    background-color: white;
    margin: 0;
    font-size: 0.8vw;
    border-radius: 0 5px 5px 0;
    border: solid lightgray 1px;
    height: 60%;
}

.buttonTripleDonutCenter {
    font-weight:bold;
    color: lightgray;
    background-color: white;
    margin: 0;
    font-size: 0.8vw;
    border-radius: 0px;
    border: solid lightgray 1px;
    height: 60%;
}

.activeButton {
    color: black;
    background-color: lightgray;
}

.bodyButtonTripleDonut {
    margin-top: 1vw;
    display: flex;
    max-height: 2.15vw;
    min-height: 2.15vw;
    margin-right: 1vw;
}

.bodyPunti {
    margin-left: 10px;
}


.punto {
    border-radius: 50%;
    background-color: lightgray;
    width: 5px;
    height: 5px;
    margin: 3px
}


.singleMultipleDonut {

    /* NEW CSS */




   
    width: 7vw;


    /* NEW CSS */

}

.elementTitleSingleDonut {
    text-align: center;
    margin: 0;
    font-size: 0.6vw;
}