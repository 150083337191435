.boxDesktopIconaNavigazione{
    
    cursor: pointer;
   
}

.boxTitleDesktopIconaNavigazione{
    /* OLD CSS */
    /*font-size: 2vh;*/
    font-weight: bolder;
    /*margin-right: 30px;*/
    color: lightgray ;
    /*margin-left: 10px;*/

    /*NEW CSS*/
    font-size: 1.25vw;
    margin-left: 1.18vw;
    margin-right: 3.19vw;
}


.boxDesktopIconaNavigazioneActive{

    /* OLD CSS */
    font-size: 1.25vw;
    font-weight: bolder;
    /*margin-right: 30px;*/
    color: gray ;
    /*margin-left: 10px;*/

    /*NEW CSS*/
    margin-left: 1.18vw;
    margin-right: 3.19vw;
}

.linkDesktopIconaNavigazione{
    text-decoration: none;
    display: flex;
    align-items: center;
}

