.mainMenuStylGeneral{
    /*OLD CSS*/
    display: flex;
    /*margin:10px 20px ;*/

    /* NEW CSS */
    max-width: 86.67vw;
    margin:auto;
    width: 100%;
    /*height: 6.67vh;*/
    height: 4vw;
    margin-top: 2.1vw;
    margin-bottom: 2.7vw;
    align-items: center;
}


.menuPrimaParteSinistra{
    /*OLD CSS*/
    display: flex;
    flex: 1;

    /* NEW CSS */
}

.menuPrimaParteDestra{
    /*OLD CSS*/
    flex: 1;
    display: flex;
    justify-content: flex-end;

    /* NEW CSS */
   
}

body{
    /* OLD CSS*/
    background-color: #F4F8FB;
    /*margin:0 30px;*/

    /* NEW CSS */
}
