.bodyAddNewAsset {
    /*OLD CSS*/
    border-radius: 5px;
    /*width: 100%;*/

    /*NEW CSS*/
    width: 86.67vw;
    position:fixed;
    background-color:white;
    top:1vw;
    left: 6vw;

}
.elementInputAddNewAsset::placeholder {
    /* Usa text-indent per aggiungere uno spazio a sinistra del testo del placeholder */
    text-indent: .7vw;
}
.elementCloseSchedaAddNewAsset {
    /*OLD CSS*/
    cursor: pointer;
    margin: 0;
    margin-right: 1vw;

    /*NEW CSS*/
    font-size: 2.2vw;
}

.boxTitleAddNewAsset {
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    /*margin: 2vh 0*/
    /*NEW CSS*/
    margin-bottom: .7vw;
    align-items: center;
}

.elementTitleAddNewAsset {
    /*OLD CSS*/
    margin: 0;
    /*NEW CSS*/
    font-size: 2vw;
    color: #495672;


}

.boxFormAddNewAsset {
    display: flex;

}

.boxBlockFormAddNewAsset {
    /*OLD CSS*/
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    /*margin-bottom: 2vh;*/
    /*padding-bottom: 1vh;*/
    /*NEW CSS*/
    /*margin-right: 5.56vw;*/
    padding:.85vw 0 0.85vw 0;
    display: flex;
    flex-direction: column;

}
.correctionelementSecondaryRightboxBlockFormAddNewAsset{
    margin-top: 1vw;
    padding-bottom: 0.85vw;
}
.correctionelementPrimaryRightboxBlockFormAddNewAsset{
    padding-bottom: .85vw;
}
.boxColumnFromAddNewAsset {
    /*OLD CSS*/
    flex: 6;
    /*NEW CSS*/
    width: 34vw;
    display: flex;
    flex-direction: column;

}

.elementSpaceBetweenAddNewAsset {
    flex: 1;
}

.boxAllAddNewAsset {
    /*OLD CSS*/
    /*margin: 0 2vw;*/
    /*NEW CSS*/
    padding: 2vw 8.34vw 2vw 4.58vw;
}

.elementSubTitleFormAddNewAsset {
    /*OLDCSS*/
    /*margin-left: 1vw;*/
    /*NEW CSS*/
    margin:0;
    font-size: 1.7vw;
    margin-bottom: 1.4vw;
    margin-left: 1.95vw;
}


.elementInputAddNewAsset {
    /*OLD CSS*/
    /*width: 80%;*/
    /*margin: 1vh 0 1vh 1vw;*/
    /*height: 4vh;*/
    background-color: #F4F4F4;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    border: solid 1px lightgray;
    /*NEW CSS*/
    height: 2.8vw;
    width: 24vw;
    margin-left: 1.95vw;
    padding:0;
    font-size: 1.2vw;
    margin-bottom: 1.4vw;
    text-indent: .7vw;
}

.elementInputAddNewAssetHandleYear{
    margin:0;
    width: 11.3vw;
}
.elementInputAddNewAsset:focus {
    border: solid 1px black;
}

.elementInputAddNewAsset::placeholder {
    font-weight: normal;

}



.boxInputAllineatiAddNewAsset {
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    /*width: 80%;*/
    /*NEW CSS*/
    width: 24vw;
    margin-left: 1.95vw;
    border: solid 1px #d3d3d300;
    margin-bottom: 1.4vw;

}
.elementButtonSubmitFormAddNewAsset{
    width: 100%;
    height: 3.5vw;
    font-size: 1.7vw;
    margin: auto;
    font-weight: 700;
}

input[type=file] {
    display: none;
}

.elementInputMultiFileAddNewAsset {
    width: 80%;
    margin: 1vw 0 1vw 1vw;
}




.elementLableInputMultiFileAddNewAsset {
    margin: 0;
    font-size: normal;
    margin-right: 1vw;
    font-weight: bold;
    padding: 0.1vw 1vw;
    cursor: pointer;
}


input:required:invalid,
input:focus:invalid {
    border-color: red;

}

select:required:invalid,
select:focus:invalid {
    border-color: red;
}



input:required:valid {}


.selectDatiImpianto:focus {
    border: solid 1px black;
}

.selectDatiImpianto::placeholder {
    font-weight: normal;
}
.selectDatiImpianto::placeholder {
    /* Usa text-indent per aggiungere uno spazio a sinistra del testo del placeholder */
    text-indent: .7vw;
}

.selectDatiImpianto {
    /*OLD CSS*/
    /*width: 80%;*/
    /*margin: 1vh 0 1vh 1vw;*/
    /*height: 4vh;*/
    background-color: #F4F4F4;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    border: solid 1px lightgray;
    /*NEW CSS*/
    height: 2.8vw;
    width: 24vw;
    margin-left: 1.95vw;
    padding:0;
    font-size: 1.2vw;
    margin-bottom: 1.4vw;
    text-indent: .7vw;
}