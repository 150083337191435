/*sfondo della modale*/
.modal {
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  /*
    width: 80%;
    */

  max-height: 100%;
  top:5%;
  left: 10%;
  
  overflow: auto;
}


.display-block {
  display: block;
  
}

.display-none {
  display: none;
}