.elementDisactiveButtonTwoState{
    /*OLD CSS*/
    /*margin:0.5vh 0.5vw;*/
    /*padding:0.5vh 0.5vw;*/
    background-color: white;
    border:none;
    cursor: pointer;

    /*NEW CSS*/
    font-size: 0.97vw;
    margin-right: 1.25vw;
    font-weight: 400;
    padding: 0 1vw 0 1vw;
}

.activeButtonTwoState{
    /*OLD CSS*/
    /*margin:0.5vh 0.5vw;*/
    /*padding:0.5vh 0.5vw;*/
    background-color: lightblue;
    border-radius: 25% 0 25% 0;
    border:none;
    color: #495672;

    /*NEW CSS*/
    padding: 0 1vw 0 1vw;
}