.boxNotificheElement {
    /*OLD CSS*/
    display: flex;
    align-items: center;
    position: relative;

    /*NEW CSS*/
    margin-right: 1.6vw;



}

.logoNotificheElement {
    /*OLD CSS*/
    /*width: 20px;*/
    /*height: 20px;*/
    background-color: white;
    border-radius: 50%;
    /*padding: 10px;*/
    cursor: pointer;

    /* NEW CSS*/
    width: 2.082vw;
    height: 2.082vw;
    padding: 1.05vw;

}

.notifichePresentiNotificheElement {
    /* OLD CSS */
    border-radius: 50%;
    /*padding: 5px;*/
    background-color: red;
    position: absolute;
    z-index: 1001;
    top: 0;
    /*right: 0;*/

    /*NEW CSS*/
    width: 0.973vw;
    height: 0.973vw;
    right: 0.35vw;

}

.boxElencoNotifiche {
    /*OLD CSS*/
    position: absolute;
    /*top: 45px;*/
    /*left: -1.3vw;*/
    background-color: white;
    /*padding: 10px;*/
    border-radius: 5px;
    /*min-width: 200px;*/
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    z-index: 1000;

    /*NEW CSS*/
    width: 24vw;
    height: 20vw;
    padding: 1vw 1.45vw 1vw 1.45vw;
    top: 4.169vw;
    left: -5.4vw;
}

.desktopTitoloRowNotifiche {
    /* OLD CSS*/
    /*font-size: small;*/
    font-weight: bold;
    padding: 0;
    margin: 0;

    /*NEW CSS*/
    font-size: 0.97vw ;
}

.desktopAvvisoRowNotifiche {
    /* OLD CSS*/
    /*font-size: x-small;*/
    font-weight: normal;
    padding: 0;
    margin: 0;

    /*NEW CSS*/
    font-size: 0.84vw ;
}

.desktopColorBackUnActive {
    color: gray;
}
.desktopColorBackActive {
    color : black;
}

.desktopRowElement {
    /*OLD CSS*/
    /*margin-bottom: 5px;*/
    cursor: pointer;

    /*NEW CSS*/
    margin-bottom: .1vw;
}

.desktopRowNotificheUnActiveStyleLink:link, .desktopRowNotificheUnActiveStyleLink:active, .desktopRowNotificheUnActiveStyleLink:visited    {
    text-decoration: none;
    color: none;
}

.elementTilteNotification{
    /*OLD CSS*/
    /*margin: 0 0 5vh 0;*/

    /*NEW CSS*/
    margin: 0 0 0 0;
    font-size: 1.7vw;
}

.boxFiltroTutteNonLetteNotification{
    /*OLD CSS*/
    display: flex;
    /*margin: 2vh 0;*/
    align-items: baseline;

    /*NEW CSS*/
    margin: 1.45vw .6vw;
}

.elementLinkElenecoNotificheCompletoNotification{
    /*OLD CSS*/
    /*font-size: xx-small;*/
    margin-left: auto;
    color: blue;
    text-decoration: none;

    /*NEW CSS*/
    font-size: 0.97vw;
    
}

.boxlistNotification{

    /*OLD CSS*/
    /*max-height: 50vh;*/
    overflow-y: auto;
    margin-left: 0.5vw;

    /*NEW CSS*/
    max-height: 14vw;
}