.bodyDesktopMachineFaultList {
    background-color: white;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    /* NEW CSS */
    /*margin-top: 2vw !important;*/
    width: 80.35vw;
    margin: auto;
}

.TitoloDesktopMachineFaultList {
    /*margin-left: 10px;*/
    /*padding-top: 5vh;*/

    /*NEW CSS */
    font-size: 1.45vw;
    padding: 0.87vw 0 0 1.04vw;
    margin: 0;
}

.headTableDesktopMachineFaultList {
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* NEW CSS */
    gap: 1.16vw;
    margin-left: 1.04vw;
    text-transform: uppercase;
    color: #464F60;

    /*padding: 0 2.90vw 0 0.58vw;*/
}

.RowTableDesktopMachineFaultList {
    display: flex;
    justify-content: space-between;
    /*max-height: 50px;*/
    align-items: center;

    /* NEW CSS */
    /*max-height: fit-content;*/
    gap: 1.16vw;
    padding-left: 1.04vw;
    padding-top: 0.625vw;
    padding-bottom: 0.625vw;
    min-height: 2.5vw;
}

.ElemntStandardDesktopMachineFaultList {
    /*font-size: 1.7vh;*/

    /* NEW CSS */
    flex: 2 !important;
    font-size: 0.9vw;
    color: #464F60;
}

.ElemntLongDesktopMachineFaultList {
    flex: 10;
}

.backgroundWhite {
    background-color: white;
}

.backgroundBlue {
    background-color: #F4F8FB;
}

.testoStandardDesktopMachineFaultList {
    font-weight: normal;
}

.RowTableDesktopMachineFaultList h6 {
    margin: 0;
}

.headerClickAngularParentheses {
    cursor: pointer;
    display: flex;
    transform: scale(-0.5, 1);
    flex: 0.2;
    justify-content: center;


}

.boxHeaderRulPersMachineFaultList {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.elementDateHeaderRulPersMachineFaultList {
    /*font-size: 1.9vh;*/
    display: flex;
    justify-content: center;
    flex: 1;

    /* NEW CSS */
    font-size: 1vw;
}


/*
Da modificare quando abbiamo la definizione precisa dei termini
*/


.elementStateTicket-0 {
    font-weight: normal;
    cursor: pointer;
    color: gray;
    background-color: lightgrey;
    border-radius: 10px;
    padding: 2px 10px
}

.elementStateTicket-1 {
    font-weight: normal;
    color: red;
    background-color: lightcoral;
    border-radius: 10px;
    padding: 2px 10px
}

.elementStateTicket-2 {
    font-weight: normal;
    color: green;
    background-color: lightgreen;
    border-radius: 10px;
    padding: 2px 10px
}