
.boxInputTecnicalDocumentationsAddNewAsset{
    width: 80%;
margin: 1vh 0 1vh 1vw;
height: 4vh;
background-color: #F4F4F4;
border-radius: 5px;
color: black;

border:solid 1px lightgray;
display: flex;
align-items: center;
justify-content: space-between;

}

.boxUploadTecnicalDocumentationsAddNewAsset{
    cursor: pointer;
}

.elementTecnicalDocumentationsAddNewAsset{
    margin:0;
    font-size: normal;
    color: grey;
}