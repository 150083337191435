.bodyMultiVariableDonut{

    /*OLD CSS*/
    /*margin:10px  20px; */

    /*NEW CSS*/
    margin: auto;
    max-width: 86.67vw;
}

.bodyTripleMultipleDonut{
    background-color: white;
    /* NEW CSS */
    min-width: 46.66vw;
    min-height: 18.7vw;
    max-width: 46.66vw;
    max-height: 18.7vw;
    margin-right: 1.67vw;
    /* margin-bottom: 2vh; */
    /* NEW CSS */

  
    border-radius: 5px;
    /* padding:10px; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
 
}

.singleMultipleDonut{

     /* NEW CSS */

   
 
   
    height: 10vw;
    width: 10vw;

    
    /* NEW CSS */
    
}

.provaResponsiveMultipleDonut{

    min-height: 40.67vw;
    min-width: 40.67vw;
    max-height: 40.67vw;
    max-width: 40.67vw;
}