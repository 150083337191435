.bodyGraficoLienare{
    /* background-color: white;
    border-radius: 5px;
    padding:10px;
    
    margin-left: 10px;
    margin-right: 20px;
    flex: 1; */
    width: 100%;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    background-color: white;
    padding-left: 1.5vw;
    padding-top: 1.3vw;
    padding-right: 1.5vw;
   
   
}



/* Cosi come per gli altri componenti che hanno il menù di week,month,year creo un classe css 
per un container padre che andrà a contenere il titolo e il menù suddetto e ragiono sui margini
a partire dal padre e non nei figli */

/*Per il momento non aggiungo la classe css per il menù week/month/year e aspetto la decisione di Luca*/

.titleLinearGraph{
    display: flex;
    align-items: top;
    justify-content: space-between;
    flex: 1;
    margin-right: 1vw;
   
    margin-bottom: 1vw;
    
}

.titleLinearGraph2{
    display: flex;
    align-items: top;
    justify-content: space-between;
    flex: 1;
    margin-right: 1vw;
    
    font-size: 1.2vw;
    margin-top: 1vw;
    
}

.titleLinearGraph2 h3 {
    margin: 0;
    margin-top: 1vw;
    margin-bottom: 1vw;
}

