.bodyDatiTarga {
    display: flex;
    flex: 2;
    /*margin-top: 2vw;*/
}

.bodyTableMachineDocument {
    background-color: white;
    width: 100%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    /*flex: 2;*/
    /*margin-left: 10px;*/
    /*margin-right: 10px;*/

    /* NEW CSS */
    /*width: 31.25vw;*/
    width: 100%;
    /*margin: auto;*/
    /*min-height: 20.695vw;*/
    /*max-height: 40.11vh;*/
    /*min-height: 23.11vh;*/

    margin-right: 1.5vw;
    height: 20.7vw;
}


/* Secondo bodyTableMachineDocument */
.bodyTableMachineDocument:nth-child(2) {
    /*width: 50.6185vw;*/
    width: 100%;
}

.elementDatiTargaList {
    /*margin: 0*/
    padding: 0;
    margin-left: 2.64vw;
    /*margin-top: 2.12vh;*/

    /* NEW CSS */
    margin-top: 1vw;
}

.elementDatiTargaElementList {
    margin: auto;
    display: flex;
    margin: 0
}

.elementDatiTargaElementListName {
    flex: 1;
    /*margin: 1vh 0;*/
    /* NEW CSS */
    margin: 0.3vw 0;
    color: rgba(0, 0, 0, 0.3);
    font-size: 0.8vw;
}

.elementDatiTargaElementListValue {
    flex: 1;
    /*margin: 1vh 0;*/
    /* NEW CSS */
    margin: 0.3vw 0;
    font-size: 0.8vw;
}

.elementTitoloTableMachineDocument {
    /*margin-left: 10px;*/
    /*padding-top: 5vh;*/
    /*font-size: 3vh;*/

    /* NEW CSS */
    font-size: 1.45vw;
    padding: 0.87vw 0 0 1.04vw;
    margin: 0;
}

.boxHeadTableMachineDocument {
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* NEW CSS */
    gap: 1.16vw;
    margin-left: 2.64vw;
    text-transform: uppercase;
    color: #464F60;
    font-size: 1.2vw;
    /*margin-top: .78vh;*/

}

.boxRowTableMachineDocument {
    display: flex;
    justify-content: space-between;
    max-height: 50px;
    align-items: center;

}

.elementStandardTableMachineDocument {
    /*OLD CSS*/
    flex: 1;
    /*margin-left: 10px;*/

    /*NEW CSS*/
    /*margin-top: 1.34vh;*/
    /*margin-bottom: 1.34vh;*/
    margin-top: 0.8vw;
    margin-bottom: 0.8vw;
}

.elementLongTableMachineDocument {
    /*OLD CSS*/
    flex: 4;
    /*margin-left: 10px;*/

    /*NEW CSS*/
    margin: 0;
}

.backgroundWhiteTableMachineDocument {
    background-color: white;
}

.backgroundBlueTableMachineDocument {
    background-color: #F4F8FB;
}

.elementTestoStandardTableMachineDocument {
    font-weight: normal;

}