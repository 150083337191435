.bodyAddStabilimento {
    /*OLD CSS*/
    border-radius: 5px;
    /*width: 100%;*/
  
    /*NEW CSS*/
    width: 86.67vw;

}

.boxAllAddNewStabilimento {
    /*OLD CSS*/
    /*margin: 0 2vw;*/
    /*NEW CSS*/
    position: fixed;
    left:25vw;
    top:5vw;
    background-color: white;

    padding: 2vw   6vw;
}

.boxTitleAddNewStabilimento {
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    /*margin: 2vh 0*/
    /*NEW CSS*/
    width: 40vw;
    color: #495672;
    margin-bottom: .7vw;
    align-items: center;
}

.elementCloseSchedaAddNewStabilimento {
    /*OLD CSS*/
    cursor: pointer;
    margin: 0;
    margin: 0vw 1vw;
    /*NEW CSS*/
    font-size: 2vw;
    color: #B42318;
}

.elementTitleAddNewStabilimento {
    /*OLD CSS*/
    margin: 0;
    /*NEW CSS*/
    font-size: 2vw;
   
}


.elementInputAddNewStabilimento {
    /*OLD CSS*/
    /*width: 80%;*/
    /*margin: 1vh 0 1vh 1vw;*/
    /*height: 4vh;*/
    background-color: #F4F4F4;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    border: solid 1px lightgray;
    /*NEW CSS*/
    height: 2.8vw;
    width: 24vw;
    margin-left: 2vw;
    padding: 0;
    font-size: 1.2vw;
    margin-bottom: 1.4vw;
    text-indent: .7vw;
}

.elementPosGeoAddNewStabilimento {
    background-color: #F4F4F4;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    border: solid 1px lightgray;
    /*NEW CSS*/
    height: 2.8vw;
    width: 23vw;
    margin-left: 1.95vw;
    padding: 0;
    font-size: 1.2vw;
    margin-bottom: 1.4vw;
    /*text-indent: .7vw;*/
    padding-left: 1vw;
}

.elementButtonSubmitFormAddNewStabilimento {
    width: 100%;
    height: 3.5vw;
    font-size: 1.7vw;
    margin-top: 1vw;
    font-weight: 700;
}

.boxBlockFormAddNewStabilimento {
    /*OLD CSS*/
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    /*margin-bottom: 2vh;*/
    /*padding-bottom: 1vh;*/
    /*NEW CSS*/
    /*margin-right: 5.56vw;*/
   

}

.boxFormAddNewStabilimento {
    display: flex;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.boxColumnFromAddNewStabilimento {
    /*OLD CSS*/
    flex: 1;
    /*NEW CSS*/
    
    display: flex;
    flex-direction: column;
}

.elementSpaceBetweenAddStabilimento {
    flex: 1;
}

.elementInputAddNewStabilimentoBudget {
    /*OLD CSS*/
    /*width: 80%;*/
    /*margin: 1vh 0 1vh 1vw;*/
    /*height: 4vh;*/
    background-color: #F4F4F4;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    border: solid 1px lightgray;
    /*NEW CSS*/
    height: 2.8vw;
    width: 100%;
    /*margin-left: 1.95vw;*/
    padding: 0;
    font-size: 1.2vw;
    /*margin-bottom: 1.4vw;*/
    text-indent: .7vw;
    margin-bottom: 0.3vw;
}

.boxBudgetStabilimento {
    margin-bottom: 1.4vw;
    margin-left: 1.95vw;
    width: 24vw;
}


.elementoLableForm{
    font-size: 1.2vw;
    color: black;
    margin-left: 1.5vw;
    padding: 0.5vw;
    font-weight: bolder;
}