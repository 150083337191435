.elementArrowListTimeEvent{
    font-size: larger;
    margin:auto;
    margin-left: 1vw;
    
}




.bloccoTitoloListEvent{
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    /*margin : 0 10px;*/

    /*NEW CSS*/
    font-size: 1.2vw;
    margin: 1.1vw 2.5vw 1.1vw 2.5vw;
    align-items: center;

}

.boxTitleEventList{
    /*OLD CSS*/
    display: flex;
    /*NEW CSS*/
    font-size: 1.2vw;
    
}

.corpoEvent{
    /*OLD CSS*/
    color:black;
    /*NEW CSS*/
    font-size:1vw;
    display:flex;
    flex-direction:column;
}

.elementTitleListTimeEvent{
    /*OLD CSS*/
    cursor: pointer;
    /* NEW CSS*/
    margin:0;


}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.titleEventListTimeEvent{
    /*OLD CSS*/
    margin:0;

    /*NEW CSS*/
    font-size: 0.97vw;
}
.boxLiElementListStandard{
    /*OLD CSS*/
    /*margin: 3vh 0;*/
    display: flex;
    align-items: center;

    /*NEW CSS*/
    margin: 0vw 1vw 0 1vw;
    margin-bottom: 1.1vw;
}

.ElementUlListActivityTime{
    padding: 0
}

.elementReportIncomplateListActivity{
    color: crimson;
}


.elementReportComplateListActivity{
    color: green;
}


.elementSpecificDataToInterventionLittle{
    font-size:0.8vw;
    white-space: nowrap;
    overflow: hidden;
    width:15vw;
    text-overflow: ellipsis;
}