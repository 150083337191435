
.elementSliderOnOff{
    width: 10%;
    margin-top: auto;
}

/*
    DEFINIZIONE DEI PUNTI DI SLIDER
*/
/*
input[type="range"]::-webkit-slider-runnable-track{
    -webkit-appearance: none;

}

input[type="range"]::-moz-range-track{
    -moz-appearance: none;
}

input[type="range"]::-ms-track{
    appearance: none;
}
*/

/*



input[type="range"]::-webkit-slider-thumb{
    -webkit-appearance: none;
    height: 0.7vw;
    width: 0.7vw;
    bottom:0;
    top:0;
    background-color: #1c7293;
    cursor: pointer;
    pointer-events: auto;
    margin-top: 0.5vw;
    border-radius: 0.4vw;
}

input[type="range"]::-moz-range-thumb{
    -webkit-appearance: none;
    height: 0.7vw;
    width: 0.7vw;
    bottom:0;
    top:0;
    background-color: #1c7293;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -0.5vw;
    
}

input[type="range"]:active::-webkit-slider-thumb{
    background-color: white;
    border: 1px solid black;
    border-radius: 0.4vw;
}

input[type="range"]:active::-moz-range-thumb{
    background-color: white;
    border: 1px solid black;
}

*/