.titleLoadDonutChart{
    text-align: center;
    /*font-size: 1.15vw;*/
    /*min-width: 20vw;*/
    /*max-width: 20vw;*/

    /* NEW CSS */
    margin: 0;
    font-size: 1vw;
}


.bodyLoadDonutChart{
    display: flex;
    flex-direction: column;
    align-items: center;

    /* NEW CSS */
    /*min-width: 12.36vw;*/
    /*max-width: 12.36vw;*/
    margin: 1vw;
    
}

.graf1{
   /* max-width: 8.81vw;
   min-width: 8.81vw;
   max-height: 12vh;
   min-height: 12vh; */


   /*margin-bottom: 4vh ;*/

}

.graf2{
    /* max-width: 8.81vw;
    min-width: 8.81vw;
    max-height: 10vh;
    min-height: 10vh; */


    /*margin-bottom: 4vh ;;*/

}

.graf3{
    /* max-width: 8.81vw;
    min-width: 8.81vw;
    max-height: 10vh;
    min-height: 10vh; */


    /*margin-bottom: 4vh ;*/
}

