.bodyAddDepartment {
    /*OLD CSS*/
    border-radius: 5px;
    /*width: 100%;*/
    /*NEW CSS*/
    width: 86.67vw;

}
.boxAllAddNewDepartment {
    /*OLD CSS*/
    /*margin: 0 2vw;*/
    /*NEW CSS*/
    position: fixed;
    left:25vw;
    top:5vw;
    background-color: white;
    padding: 2vw 8.34vw 2vw 4.58vw;
}
.boxFormAddNewAsset {
    display: flex;

}
.boxTitleAddNewDepartment {
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    /*margin: 2vh 0*/
    /*NEW CSS*/
    margin-bottom: .7vw;
    align-items: center;
}
.elementTitleAddNewDepartment {
    /*OLD CSS*/
    margin: 0;
    /*NEW CSS*/
    font-size: 2vw;
    color: #495672;

}
.elementCloseSchedaAddNewAsset {
    /*OLD CSS*/
    cursor: pointer;
    margin: 0;
    margin: 0vw 1vw;
    /*NEW CSS*/
    font-size: 2vw;
    color: #B42318;
}
.boxFormAddNewDepartment{
    display: flex;
   
}
.elementSpaceBetweenAddDepartment {
    flex: 1;
}
.boxColumnFromAddNewDepartment {
    /*OLD CSS*/
    /*flex: 6;*/
    /*NEW CSS*/
}
.elementButtonSubmitFormAddNewDepartment{
    width: 100%;
    height: 3.5vw;
    font-size: 1.7vw;
    margin-top: 1vw;
    font-weight: 700;
}
.boxColumnFromAddNewDepartment {
    /*OLD CSS*/
    /*flex: 6;*/
    /*NEW CSS*/
}

.boxBlockFormAddNewAsset {
    /*OLD CSS*/
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    /*margin-bottom: 2vh;*/
    /*padding-bottom: 1vh;*/
    /*NEW CSS*/
    /*margin-right: 5.56vw;*/
    padding:1vw 0 0 0vw;

}

.elementInputAddNewDepartment {
    /*OLD CSS*/
    /*width: 80%;*/
    /*margin: 1vh 0 1vh 1vw;*/
    /*height: 4vh;*/
    background-color: #F4F4F4;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    border: solid 1px lightgray;
    /*NEW CSS*/
    height: 2.8vw;
    width: 24vw;
    margin-left: 1.95vw;
    padding:0;
    font-size: 1.2vw;
    margin-bottom: 1.4vw;
    text-indent: .7vw;
}
.boxBudget{
    margin-bottom: 1.4vw;
    margin-left: 1.95vw;
    width: 24vw;
}
.elementInputAddNewDepartmentBudget {
    /*OLD CSS*/
    /*width: 80%;*/
    /*margin: 1vh 0 1vh 1vw;*/
    /*height: 4vh;*/
    background-color: #F4F4F4;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    border: solid 1px lightgray;
    /*NEW CSS*/
    height: 2.8vw;
    width: 100%;
    /*margin-left: 1.95vw;*/
    padding:0;
    font-size: 1.2vw;
    /*margin-bottom: 1.4vw;*/
    text-indent: .7vw;
    margin-bottom: 0.3vw;
}
.budgetText{
    font-size: .9vw;
}
.budgetText.correctBudget {
    color: green;
}
.budgetText.errorBudget {
    color: red;
}
.elementInputAddNewDepartment:focus {
    border: solid 1px black;
}

.elementInputAddNewDepartment::placeholder {
    font-weight: normal;
}
.elementInputAddNewDepartment::placeholder {
    /* Usa text-indent per aggiungere uno spazio a sinistra del testo del placeholder */
    text-indent: .7vw;
}