
.menu-button{
    font-size: 1.2vw !important;
    padding-top: 1vw !important;
    padding-bottom: 1vw !important;
    padding-right: 2vw !important;

    .menu-container {
        cursor: pointer;
        z-index: 1000;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;
        .ball {
            cursor: pointer;
            width: .5vw;
            height: .5vw;
            background-color: #333;
            border-radius: 50%;
            margin-bottom: .3vw;
            transition: background-color 0.3s ease-in-out;
        }
    }
}
.bodyVerticalMenu{
    position: absolute;
    background-color: white;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    padding: 1vw 1.45vw 1vw 1.45vw;
    max-width: 11.65vw;
    max-height: fit-content;
    right: 5vw;
}
.menuElement{
    display: flex;
    cursor: pointer;
    align-items: center;
    margin: 0.3vw 0;
}
.testoMenuElement{
    padding:0px;
    font-size: 0.97vw ;
    margin:0 .3vw;
}
.boxAllDeleteObject {
    padding: 2vw 4.58vw 2vw 4.58vw;
}
.boxTitleDeleteObject {
    display: flex;
    justify-content: space-between;
    margin-bottom: .7vw;
    align-items: center;
}
.elementTitledeleteObject {
    margin: 0;
    font-size: 1.5vw;
}

.bodydeleteObject {
    border-radius: 5px;
    width: 50vw;

}

.elementSpaceBetweenDeleteObject {
    flex: 1;
}
.elementButtonSubmitDeleteObject{
    width: 100%;
    height: 2.2vw;
    font-size: 1.5vw;
    margin-top: 1vw;
    font-weight: 700;
}
.deleteObjectButton{
    display: flex;
}
.treeObject{
    .text-red{
        color:red;
    }
}