.boxMainBarraNavigazione {
    /*OLD CSS*/
    background-color: white;
    display: flex;
    margin:auto;

    /*margin:10px 20px ;*/
    /*padding:10px 20px;*/

    /*NEW CSS*/
    width: 100%;
    /*height: 8.2vh;*/
    height: 5vw;
    max-width: 86.67vw;
}

.boxLeftBarraNavigazione {
    /*OLD CSS*/
    display: flex;

    /* NEW CSS*/
    align-items: center;
    margin-left: 2.3vw;
}

.boxRightBarraNavigazione {
    /*OLD CSS*/
    display: flex;
    margin: auto;
    /*margin-right: 0px;*/

    /* NEW CSS*/
    margin-right: 2.3vw;
}


@media (max-width: 1360px) and (max-height: 766px) {


}

@media (min-width: 1361px) and (max-width: 1960px) and (max-height: 1080px) {

}


@media (min-width: 1961px) and (max-width: 2560px) and (max-height: 1600px) {

}

@media (min-width: 2561px) and (min-height: 1601px) {


}