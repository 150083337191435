.bodyDesktopFormInserimetoEvento {
    /*
    position: absolute;
    top: 50px;
    right: 200px;
    */
    /*padding: 10px;*/
    border-radius: 5px;
    background-color: white;
    border: solid 1px #F4F4F4;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    z-index: 1;
    position: fixed;
    left:25vw;
    /*
    min-width: 400px;
    */
    /*width: 35vw;*/


    color: black;

    /*NEW CSS*/
    width: 41.3vw;
    padding: 2vw 2vw 2vw 2vw;
    max-height: 80vh;
    overflow-y: auto;


}


.elementInputFormNewEvent {
    /*OLD CSS*/
    background-color: #F4F4F4;
    border-radius: 5px;
    border: solid 1px lightgray;
    width: 100%;
    /*padding-left: 10px;*/

    /*NEW CSS*/
    height: 2.8vw;
    margin-top:1vw;
    padding:0;
    font-size: 1vw;
    /*padding-left: 1.05vw;*/
    text-indent: .7vw;
}
.elementInputFormNewEvent::placeholder {
    /* Usa text-indent per aggiungere uno spazio a sinistra del testo del placeholder */
    text-indent: .7vw;
}
.elementInputFormNewEventPartial {
    background-color: #F4F4F4;
    border-radius: 5px;
    border: solid 1px lightgray;



}
.correctionSpaceelementInputFormNewEvent{
    width: 90% !important;
}
.correctionHeightelementInputFormNewEventNote{
    height: 7.5vw !important;
}
.correctionelementSecondaryTitleFormNewEventNote{

}
.elementInputFormNewEventFlex {
    /*OLD CSS*/
    background-color: #F4F4F4;
    border-radius: 5px;
    border: solid 1px lightgray;
    /*padding-left: 10px;*/

    /*NEW CSS*/
    margin-top: 1vw;
    height: 2.8vw;
    width: 11.6vw;
    font-size: 1vw;
    /*padding-left: 1.05vw;*/
    text-indent: .7vw;



}

.elementTitleFormNewEvent {
    margin: 0;
}

.elementCloseFromNewEvent {
    margin: 0;
    cursor: pointer;
}

.elementSecondaryTitleFormNewEvent {
    /*OLD CSS*/
    color: grey;
    /*margin: 1vw 0 0 0;*/

    /*NEW CSS*/
    margin-top:1vw;
    margin-bottom: 0;
    font-size: 1.35vw;
}

.boxDateFormNewElement {
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;

    /*NEW CSS*/


}

.boxFlexColumnFormNewElement {
    /*OLD CSS*/
    display: flex;
    flex-direction: column;
    justify-content: right;
    /*margin-right: 0.5vw;*/

    /*NEW CSS*/

}

.elementFormNewEventArrowUp {
    position: absolute;
    top: -10px;

}

.elementFormNewEventArrowDown {
    position: absolute;
    top: 10px;
}

.boxFormNewEventArrow {
    position: relative;
    height: 16px;
    margin: auto;
    margin-right: 10px;
}

.elementButtonSubmitFormNewEvent {
    /*OLD CSS*/
    background-color: #1496DF;
    /*padding: 5px 40px;*/
    border: solid 2px #1496DF;
    border-radius: 5px;
    color: white;
    /*margin-top: 30px;*/
    cursor: pointer;
    /*NEW CSS*/
    /*font-size: 2vw;*/
    font-size: 1vw;
    margin-top: 1vw;
    width: 10vw;
    padding-top: 1vw;
    padding-bottom: 1vw;

}
.elementButtonSubmitFormNewEvent:disabled{
    background-color: grey;
    border: solid 2px grey;
}

.boxPeriodicFormNewEvent {
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    align-content: center;

    /*NEW CSS*/


}

.boxTitleClosedFormInserimentoEvento {
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    align-items: center;

    /*NEW CSS*/
    font-size:1.7vw;
    margin-bottom: 1.4vw;
}

.boxActivityInserimentoEvento {
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    /*align-items: center;*/
    margin: 0 0;

    /*NEW CSS*/
    align-items: flex-end;


}

.descrizioneAttivita {
    /*OLD CSS*/
    font-weight: normal;
    margin: 0;

    /*NEW CSS*/
    font-size: 1vw;

}

.elementAddActivity {
    /*OLD CSS*/
    /*margin: 1vw;*/
    /*padding: 2px;*/
    border: solid 1px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bolder;

    /*NEW CSS*/
    height: 2.8vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 2.8vw;
    font-size: 1vw;

}

.boxDescrizioneAttivita {
    /*OLD CSS*/
    flex: 3;
    display: flex;
    flex-direction: column;
    /*margin-right: 1vw;*/

    /*NEW CSS*/
}

.elementCostrictInline{
    display: inline;
    white-space: nowrap;
    margin: 0 0.7vw 0 0.7vw;
}

.boxTempoAttivita {
    flex: 1;
    display: flex;
    flex-direction: column;

}

.alertInput {
    border-color: red;
}

.elementDeleteActivity {
    /*OLD CSS*/
    margin: 0;
    font-weight: bolder;
    color: red;
    cursor: pointer;

    /*NEW CSS*/
    font-size: 1vw;
}

.boxFlexBasic {
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px;

    /*margin-bottom: 1px;*/

    /*NEW CSS*/
    margin-top: 1vw;
}

.boxWidthPartial{
    width: 90%;
}

