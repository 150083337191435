.bodyListAlertOverview {
    background-color: white;
    /* NEW CSS */

    margin-right: 2vw;
    /* margin-bottom: 2vh; */
    /* NEW CSS */

    height: 20vw;
    width: 36vw;
    border-radius: 5px;
    /* padding:10px; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);

}


.titleListAlertOverview {
    display: flex;
    align-items: bottom;
    justify-content: space-between;
    flex: 1;
    margin-left: 1vw;
    margin-right: 1vw;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    text-overflow: ellipsis;

}


/* NUOVI SVILUPPI */
/* Aggiungo, come per i triple donut, il contenitore di titolo e bottone week..*/

.elementTitleAlertList {
    font-size: 1.2vw;
    margin-top: 1vw;
    margin-bottom: 1vw;

}

/* NUOVI SVILUPPI */
/* Aggiungo, come per i triple donut, il contenitore dei bottoni week,month,year */

.bodyButtonAlertList {

    display: flex;

}

/*l'altezza deve essere il doppio del font*/
.buttonListAlertOverviewLeft {
    font-weight: 500;
    background-color: white;
    color: lightgray;
    margin: 0;
    font-size: 1vw;
    border-radius: 5px 0 0 5px;
    border: solid lightgray 1px;

}

.buttonListAlertOverviewRight {
    color: lightgray;
    font-weight: 500;
    background-color: white;
    margin: 0;
    font-size: 1vw;
    border-radius: 0 5px 5px 0;
    border: solid lightgray 1px;

}

.buttonListAlertOverviewCenter {
    font-weight: 500;
    color: lightgray;
    background-color: white;
    margin: 0;
    font-size: 1vw;
    border-radius: 0px;
    border: solid lightgray 1px;

}

.activeButton {
    color: black;
    background-color: lightgray;
}

.bodyButtonListAlertOverview {
    display: flex;
    justify-content: space-between;
}

.bodyGridListaAlertOverview {}

.headerGridListAlertOverview {

    margin-left: 1vw;
    margin-right: 1vw;

    display: flex;
    align-items: center;
    justify-content: space-between;

}


.rowGridListAlertOverview {
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: space-between
}

.elementTextRowGridListAlertOverview {
    /* flex: 3; */
    text-align: center;
    font-size: 1vw;
}

.elementHealthRowGridListAlertOverview {
    /* flex: 2;
    font-size: 1.9vh;
    text-align: center;
    color: white; */
    /* NEW CSS */
    text-align: center;
    font-size: 1vw;

}

.dimensionBackgrond {
    border: solid 2px white;
    border-radius: 5px;
    padding: 5px 0;
    margin: 0 10px
}

.bodyRowsListAlertOverview {
    /* Per questo non ci sono valori quindi metto il background rosso per vedere i margini */
    background-color: white;
    overflow-y: auto;

    margin-left: 1vw;
    margin-right: 1vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}