.leaflet-container {
  /* flex: 1;
  min-width: 38.33vw;
  max-width: 38.33vw;
  min-height: 18.7vw;
  max-height: 18.7vw;
  margin: 10px 10px; */
  
  width: 100%;
  height: 87%;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
}

.leaflet-bottom{
  z-index: 500 !important;
}