.bodyListTimeEvent{
    /*OLD CSS*/
    background-color: white;
    /*margin-top:20px ;*/
    /*margin-left: 20px;*/
    border-radius: 5px;

    /*NEW CSS*/
    
    margin-left: 1vw;
    padding-top:1vw;
    padding-bottom:1vw;
}

.buttonListTimeEvent{
    /*OLD CSS*/
    background-color: #1496DF;
    /*padding: 5px 40px;*/
    border:solid 2px #1496DF;
    border-radius: 5px;
    color: white;
    /*margin-top:30px;*/
    cursor: pointer;

    /*NEW CSS*/
    /*font-size: 1.2vw;*/
    font-size: .8vw;
    padding: .5vw 0.85vw .5vw 0.85vw;
    width: 100%;
    height: 2.5vw;
    
}


.boxDesktopListTimeEventButtom{
    /*OLD CSS*/
    display: flex;
    justify-content: flex-end;
    position: relative;

    /*NEW CSS*/
    /*max-width: 44vw;*/
    max-width: 37vw;
    margin: 5.79vw 0.9vw 2vw 12vw;



}

.buttonBoxLiElementList{
    font-size: 0.97vw;

}
