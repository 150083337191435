.bodyBarMachine{
    /*OLD CSS*/
    /*min-width: 1vw;*/
    /*max-width: 15vw;*/
    display: flex;
    flex-direction: column;
    /*margin-left: 1vw;*/
    /*margin-top: 0.5vw;*/
    /*margin-bottom: 0.5vw;*/

    /*NEW CSS*/
    margin-left: 1.875vw;
    /*justify-content: flex-start;*/
    /*max-height: 5.23vh;*/
}

.elementTitleBarMachine{
    /* OLD CSS */
    /*font-size: 0.7vw;*/
    font-weight: bolder;
    color: rgba(73, 86, 114, 1);
    /*margin-bottom: 0.1vw;*/
    font-family: 'Roboto', sans-serif;
    transform: scale(1,1.2);

    /* NEW CSS */
    font-size: 0.97vw;
}

.boxNumberBarMachine{
    /*OLD CSS*/
    /*font-size: 0.7vw;*/
    display: flex;
    /*margin-bottom: 0.1vw;*/
    font-family: 'Roboto', sans-serif;

    /*NEW CSS*/
    margin-top: 0.15vw;
    font-size: 0.97vw;
}

.elementFirstValueBarMachine{
    color: rgba(73, 86, 114, 1);
    font-weight: 550;
}

.elementFirstValueBarMachine::after{
    content: '\00a0';
}

.elementSecondValueBarMachine{
    color: rgba(73, 86, 114, 0.35);
    font-weight: 550;
}


.boxBarBarMachine{
    /*OLD CSS*/
    position: relative;

    /*NEW CSS*/
    margin-top: 0.35vw;
}

.elementCompleteBarMachine{
    /* OLD CSS */
    width: 100%;
    /*height: 0.3vw;*/
    background-color: rgba(73, 86, 114, 0.35);
    position: absolute;
    border-radius: 0.2vw;

    /* NEW CSS*/
    height: 0.4vw;
}

.elementBarBarMachine{

    /*OLD CSS*/
    /*height: 0.3vw;*/
    background-color: #A8EF20;
    border-radius: 0.2vw;
    position: absolute;
    z-index: 1;

    /* NEW CSS*/
    height: 0.4vw;
}