.bodyMainReportMainteneanceForm{
   /*OLD CSS*/
    background-color: white;
    position: fixed;
    color: black;
    /*padding: 10px;*/
    border-radius: 5px;
    border: solid 1px #F4F4F4;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    /*min-width: 400px;*/
    /*width: 30vw;*/
    /*NEW CSS*/
    width: 41.3vw;
    padding: 2vw 2vw 2vw 2vw;
    max-height: 80vh;
    overflow-y: auto;
    left: 25vw ;

    

}
.elementNoteReportMaintenanceForm{
    padding: 1vw 0vw;
}

.elementSecondaryTitleReportMainteneanceForm{
    /* OLD CSS*/
    color: grey;
    /*margin:1vh 0;*/
    text-overflow: ellipsis;

    /*NEW CSS*/
    margin:0;
   
}
.correctionNoteElementSecondaryTitleReportMainteneanceForm{
    margin-bottom: .7vw;
}

.elementNoteTextReportMainteneanceForm{
    /*OLD CSS*/
    color: black;
    /*font-size: small;*/
    /*margin:10px*/
    /*NEW CSS*/
    font-size: 1vw;
}

.boxTitleReportMainteneanceForm{
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*margin:auto*/
    /*NEW CSS*/
    font-size: 1.4vw;
    margin-bottom: .7vw;
}
.correctionNoteBoxTitleReportMainteneanceForm{
    flex-direction: column;
    align-items: inherit;
}
.boxFirstTitleReportMainteneanceForm{
    /*NEW CSS*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.7vw;
    margin:0 0 1.4vw 0;
}
.elementClosignSchedaReportMainteneanceForm{
    /*OLD CSS*/
    cursor: pointer;
    margin:0
    /*NEW CSS*/
    /*font-size: 1.7vw;*/
    /*margin:0 0 2.23vh 0;*/
}

.elementTitleReportMainteneanceFrom{
    /*OLD CSS*/
    margin:0
    /*NEW CSS*/
    /*font-size: 1.7vw;*/
    /*margin:0 0 2.23vh 0;*/
}
.boxActivity{
    /*NEW CSS*/
    margin-bottom: .7vw;
    font-size: 1.4vw;
}
.boxActivityList{
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    /*NEW CSS*/
    margin-bottom: 0.4vw;
    align-items: center;
}
.checkBoxActivityList{
    width: 1.2vw;
    height: 1.2vw;
    margin-right: 0.5vw;
}
.elementActivity{
    /*OLD CSS*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*NEW CSS*/
    margin-bottom: 0.3vw;
}

.elementInputFormReport{
    /*OLD CSS*/
    background-color: #F4F4F4;
    border-radius: 5px;
    border: solid 1px lightgray;
    /*NEW CSS*/
    height: 2.8vw;
    width: 50%;
    text-indent: 0.4vw;
    font-size: 1.35vw;
}
.correctionNoteElementInputFormReport{
    width: 100%;
    height: 5vw ;
}
.elementFlexDimensionOne{
    flex:1;
}
.elementFlexDimensionThree{
    flex:20   ;
}

.elementListActivity{
    /*OLD CSS*/
    list-style-type: none;
    /*padding-inline-start: 10px;*/

    /*NEW CSS*/

}

.elementInputMaxDimension{
    max-width: 100px;
    max-height: 1.25vw;
}

.elementAlignTextLeft{
    margin-left: 0;
}


.elementReportInterventionBody{
    display: flex;
}

.elementReportColumnLeft{
    flex:1;
    display: flex;
    flex-direction: column;
}

.elementReportColumnRight{
    flex:10;
    display: flex;
    justify-content: space-between;
    border: solid 1px black;
}

.elementReportDateLeftColumn{
    font-size: 1vw;
}


.elementReportHourLeftColumn{
    font-size: 0.7vw;
}
.elementButtonSubmitMainteneanceForm{
    /*NEW CSS*/
    background-color: #1496DF;
    border: solid 2px #1496DF;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    /*font-size: 2vw;*/
    font-size: 1vw;
    /*width: 100%;*/
    width: 10vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
}