.bodySinottico {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    display: flex;
    align-items: right;
    justify-content: right;
    flex: 1;
    background-size: contain;
    background-repeat: no-repeat;
    /*height:59vh;*/
    /*margin-top: 1vh;*/
    max-width: 29.72vw;
    min-width: 29.72vw;
    /*max-height: 40.11vh;*/
    /*min-height: 40.11vh;*/
    background-color: white;

    /* NEW CSS */
    margin-right: 1.5vw;
    height: 31vw;
}

.boxDesktopSensoriSinottico {
    position: absolute;

    overflow: auto;
    overflow: hidden;
    padding-right: 1vw;
}


.bodyDesktopFinestraSinottico {
    margin-top: 10px;
    border-radius: 5px;
    background-color: #F2F2F2;
}

.titoloDesktopFinestraSinottico {
    font-size: x-small;
    font-weight: bold;
    background-color: #BFC4CE;
    border-radius: 5px;
    padding: 5px;
}

.valoreDesktopFinestraSinottico {
    font-size: xx-small;
    padding: 5px;
}

.elementImageSVGSinottico {}