.bodyDesktopDettaglioMacchinario {
    /*padding-top: 1.77vh;*/
    padding-left: 1.52vw;
    padding-right: 2.84vw;
    background-color: transparent;
    /*min-height: 74.11vh;*/
    /*max-height: 74.11vh;*/

    /* NEW CSS */
    /*height: 20.7vw;*/
    padding-top: 1.1vw;
    padding-bottom: 2vw;

}

.boxDoubleDonutDettaglioMacchinario {
    /* padding: 0 10px; */
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    /* margin-left: 10px; */
    max-width: 13.26vw;
    min-width: 13.26vw;
    /*max-height: 40.11vh;*/
    /*min-height: 40.11vh;*/
    /*margin-top: 1vh;*/
    background-color: white;

    /* NEW CSS */
    height: 31vw;
    margin-right: 1.5vw;

}

.boxDoubleGraphDettaglioMacchinario {
    /* padding: 0 10px; */
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    /* margin-left: 10px; */
    /*max-width: 32.5vw;*/
    /*min-width: 32.5vw;*/
    /*max-height: 33.11vh;*/
    /* min-height: fit-content; */
    /*margin-top: 1vh;*/
    /*padding-left: 3.14vw;*/
    background-color: white;

    /* NEW CSS */
    height: 30vw;
    width: 33vw;
    /*width:32.03vw;*/
    padding-top: 1vw;
    padding-right: 1vw;
    padding-left: 1vw;
}

.boxMacroDatiTrittico {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
}

.rowDettagliMacchinario {
    display: flex;
    /* padding: 10px; */
    background-color: transparent;

    /*NEW CSS*/
    margin-top: 0.5vw;
    margin-left: 1.3vw;
}


.elementLayerMachine {
    font-weight: normal;
    font-size: 0.877vw;
    margin: 0;
}