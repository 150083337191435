.bodyCalendar {
    /*OLD CSS*/

    /*NEW CSS*/
    margin-top: 2.23vw;
    /*max-width: 56vw;*/
}

.boxTitleCalendar {
    /*OLD CSS*/
    display: flex;
    background-color: none;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    /*margin-bottom: 2vh;*/

    /*NEW CSS*/
    margin-bottom: 1.5vw;
    height: 2vw;
}

.elementAngularHomeMadeCalendarLeft {
    /*OLD CSS*/
    cursor: pointer;
    user-select: none;
    padding: 0;
    margin: 0;
    background-color: #2c3e50;
    color: white;
    border-radius: 5px 0 0 5px;

    /*NEW CSS*/
    display: flex;
    align-items: center;

}

.elementAngularHomeMadeCalendarLeft:hover {
    background-color: #243342;
    font-weight: bolder;
}

.elementTextAngularHomeMadeCalendarLeft {

    /*font-size: x-large;*/
    margin: 0;
    /*padding: 0.3vw 1vw;*/
    transform: scale(0.5, 1);

    /*NEW CSS*/
    font-size: 1.25vw;
    padding: 0 0.5vw 0 0.5vw;

}

.elementAngularHomeMadeCalendarRigth {
    /*OLD CSS*/
    cursor: pointer;
    user-select: none;
    padding: 0;
    margin: 0;
    background-color: #2c3e50;
    color: white;
    border-radius: 0 5px 5px 0;

    /*NEW CSS*/
    display: flex;
    align-items: center;
}

.elementAngularHomeMadeCalendarRigth:hover {
    background-color: #243342;
    font-weight: bolder;
}

.elementTextAngularHomeMadeCalendarRigth {
    /*OLD CSS*/
    /*font-size: x-large;*/
    margin: 0;
    /*padding: 0.3vw 1vw;*/
    transform: scale(0.5, 1);

    /*NEW CSS*/
    font-size: 1.25vw;
    padding: 0 0.5vw 0 0.5vw;
}

.boxHomeMadeCalendar {
    /*OLD CSS*/
    display: grid;
    grid-template-columns: 9vw 9vw 9vw 9vw 9vw 9vw 9vw;

    /* NEW CSS*/
    height: 3.7vw;
    /*grid-template-columns: 7vw 7vw 7vw 7vw 7vw 7vw 7vw;*/
}

.boxHomeMadeCalendarRows {
   row-gap: 0;
   
}

.bodyDayHomeMadeCalendar {
    /*OLD CSS*/
    border: solid 1px lightgray;
    /*height: 12vh;*/

    /*NEW CSS*/
    /*height: 10.67vh;*/
    height: 7vw;
}

.selectedDay{
    border: solid 1px black;
}

.elementDayDayHomeMadeCalendar {
    font-size: 1vw;
    margin: 0;
    margin-right: 2px;
    text-align: end;
    font-weight: normal;
    user-select: none;

}

.boxEventDayHomeMadeCalendar {}

.boxHeaderHomeMadeCalendar {
    /*OLD CSS*/
    grid-template-columns: 9vw 9vw 9vw 9vw 9vw 9vw 9vw;

    /*NEW CSS*/
    /*grid-template-columns: 7vw 7vw 7vw 7vw 7vw 7vw 7vw;*/
}

.elementHeaderHomeMadeCalendar {
    font-weight: bold;
    border: solid 1px lightgray;
    text-align: center;

    /*NEW CSS*/
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9vw;
}

.boxMonthYearTitleCalendar {
    display: inline-flex;
    flex: 1;
    margin: 0;

    align-items: center;
}

.boxAngularParentesisForChangeMonth {
    /*OLD CSS*/
    display: flex;
    border-radius: 5px;

    /*NEW CSS*/
    height: 100%;

}

.elementMonthTitleCalendar {
    /*OLD CSS*/
    flex: 3;
    /*font-size: x-large;*/
    margin: 0;

    /*NEW CSS*/
    font-size: 1.25vw;

}

.elementYearTitleCalendar {
    flex: 1;
    font-size: x-large;
    margin: 0;
    margin-left: 1vw;

}

.boxListEventDayCalendarHomeMadeCalendar {
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    display: flex;
    flex-direction: column;
}

.elementEventForDayCalendar {
    /*OLD CSS*/
    /*font-size: 0.8vw;*/
    border-radius: 5px;
    /*padding: 2px 5px;*/
    font-style: italic;
    /*margin-bottom: 3px;*/

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /*NEW CSS*/
    font-size: 1vw;
    /*height: 2vh;*/
    margin-bottom: 0.3vw;
    padding: 0.1vw 0.5vw;

}


.elementInterventionCalendarTodayNext{
    background-color: orange;
    color: white;

}

.elementInterventionCalendarOld{
    background-color: red;
    color: white;

}

.elementReportIncomplateCalendarTodayNext{
    background-color: yellowgreen;
    color: white;

}

.elementReportIncomplateCalendarOld{
    background-color: crimson;
    color: white;
}

.elementReportComplete{
    background-color: green;
    color: white;
}


