.bodyInputMultiFile {
    /*OLD CSS*/
    /*width: 80%;*/
    /*margin: 1vh 0 1vh 1vw;*/
    /*height: 6vh;*/
    background-color: #F4F4F4;
    border-radius: 5px;
    color: black;

    border: solid 1px lightgray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*NEW CSS*/
    padding: 0;
    width: 24vw;
    margin-left: 1.95vw;
    font-size: 1.2vw;
    height: 2.8vw;
    margin-bottom: 1.4vw;
}


.elementNameImageInputMultiFile {
    /*OLD CSS*/
    margin: 0;
    width: 25vw;
    color: grey;
    display: flex;
    overflow-x: auto;

    /*NEW CSS*/
    text-indent: 0.7vw;
    
}

.elementNameImageInputMultiFile::-webkit-scrollbar {
    height: 1vw;
    
}

.elementNameImageInputMultiFile::-webkit-scrollbar-track {
    background: transparent;
}

.elementNameImageInputMultiFile::-webkit-scrollbar-thumb {
    margin-top: 1vw;
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
}



.elementInputMultiFile input[type=file] {
    display: none;
}

.elementInputMultiFile {
    width: 80%;
    margin: 1vw 0 1vw 1vw;
}


.elementLableInputMultiFile {
    margin: 0;
    font-size: 1.2vw;
    margin-right: 1vw;
    font-weight: bold;
    padding: 0.1vw 1vw;
    cursor: pointer;
}

.elementListNameImageInputMultifile {
    min-width: 6vw;
    height: 1.5vw;
    overflow: hidden;
    margin-left: 0.5vw;


}

.elementDeleteElementListNameImageInputMultifile {
    margin: 0 1vw;
    cursor: pointer;
    background-color: lightcoral;
    border: solid 1px red;
    border-radius: 10px;
    padding: 0 0.5vw;

}

.boxListElementNameImageInputMultifile {
    /*OLD CSS*/
    display: flex;
    width: 9vw;
    border: solid 1px;
    border-radius: 10px;
    margin-right: 1vw;
    /*NEW CSS*/
    margin-left: 0.7vw;
}