.bodyOverview{
    display: flex;
    /* margin:10px  20px;  */
    /* NEW CSS */
    /* margin-right: 6.67vw;
    margin-left: 6.67vw; */
    margin: auto;
    max-width: 86.67vw;
    min-width: 86.67vw;
    max-height: 42.6375vw;
    min-height: 42.6375vw;
    margin-top: 2.1875vw;
    margin-bottom: 3.81vw;
    /* NEW CSS */
    flex-direction: column;
    background-color: transparent;
    border-radius: 5px;


}

.elementLineargraphOverview{
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    border:1px solid;
    margin:0;
    padding: 0;
}