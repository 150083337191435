.bodySchedeExplorer{
    /*OLD CSS*/
    /*margin:10px  20px;*/

    /*NEW CSS*/
    margin: auto;
    max-width: 86.67vw;
    margin-top: 1vw;
    background-color: white;
    /*min-height: 120vh;*/
    /*max-height: 120vh;*/
}